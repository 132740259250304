import AsyncStorage from '@react-native-async-storage/async-storage'
import type { User } from '@pff-consumer/schema'

export const saveTokenToStorage = async (user: User, token: string, isPremium: boolean, isExpired: boolean) => {
  if (token) {
    try {
      await AsyncStorage.setItem('@user_token', token)
      await AsyncStorage.setItem('@user', JSON.stringify({ ...user, isPremium, isExpired }))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Could not save token', e)
    }
  }
}

export const updateTokenInStorage = async (token: string) => {
  try {
    await AsyncStorage.setItem('@user_token', token)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Could not save token', e)
  }
}

export const getTokenFromStorage = async (): Promise<string | null> => {
  try {
    const tok = await AsyncStorage.getItem('@user_token')
    if (tok) {
      return tok
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Could get token', e)
    return null
  }

  return null
}

export const saveUserToStorage = async (user: User, isPremium: boolean, isExpired: boolean) => {
  if (user) {
    try {
      await AsyncStorage.setItem('@user', JSON.stringify({ ...user, isPremium, isExpired }))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Could not save token', e)
    }
  }
}

export const isUser = (data: unknown): data is User => {
  if (typeof data === 'object' && data !== null) {
    const maybeUser: User = data as User
    if (typeof maybeUser.uid === 'string') return true
  }
  return false
}

export const getUserFromStorage = async (): Promise<User | null> => {
  try {
    const userString = await AsyncStorage.getItem('@user')
    if (userString) {
      const userData: unknown = JSON.parse(userString)

      return isUser(userData) ? userData : null
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Could user', e)
    return null
  }

  return null
}

export const removeTokenFromStorage = async () => {
  try {
    await AsyncStorage.removeItem('@user_token')
    await AsyncStorage.removeItem('@user')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Could not delete token', e)
  }
}
