import { getSession } from 'next-auth/react'
import { AnalyticsEventArgs, AnalyticsEventProperties } from '../shared/analytics-types'
import { sendHeapAnalyticsEvent } from './heap'

declare global {
  interface Window {
    sessionData?: {
      grootUid?: string
      isPremiumSubscriber?: boolean
    }
  }
}

const getBaseAnalyticsProperties = async (eventProperties: Partial<AnalyticsEventProperties>) => {
  let pffUserId
  // For subscribe.pff we do not require next-auth
  // For all the web apps which do not required next-auth, send skipNextJsAuth=true
  if (eventProperties?.skipNextJsAuth) {
    pffUserId = eventProperties?.pffUserId || window?.sessionData?.grootUid
  } else {
    const session = await getSession()
    // @ts-expect-error
    pffUserId = session?.user?.uid || session?.grootUid
  }

  return {
    createdTimestamp: Date.now(),
    loggedIn: !!pffUserId,
    pffUserId,
    version: (process.env.APP_VERSION || process.env.CODE_VERSION) ?? 'unknown',
  }
}

export const sendAnalyticsEvent = async (...args: AnalyticsEventArgs): Promise<void> => {
  try {
    const [eventName, eventProperties = {}] = args
    const baseAnalyticsProperties = await getBaseAnalyticsProperties(
      eventProperties as Partial<AnalyticsEventProperties>
    )
    const eventPropertiesWithBaseProperties = {
      ...baseAnalyticsProperties,
      ...eventProperties,
    } as Partial<AnalyticsEventProperties>

    if (eventPropertiesWithBaseProperties.skipNextJsAuth) delete eventPropertiesWithBaseProperties.skipNextJsAuth

    sendHeapAnalyticsEvent(eventName, eventPropertiesWithBaseProperties)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug(e)
  }
}
