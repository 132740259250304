export enum HistoricalPerformanceToggleOptions {
  AvgL5 = 'AVG L5',
  SimilarCoverage = 'AVG vs Similar Coverage',
  SimilarDefenses = 'AVG vs Similar Defenses',
}

export enum PerformanceMetricStat {
  PASS_YD = 'pass_yd',
  RUSH_YD = 'rush_yd',
  ANY_TIME_TD = 'any_time_td',
  PASS_TD = 'pass_td',
  PASS_ATT = 'pass_att',
  PASS_INT = 'pass_int',
  RECV_YD = 'recv_yd',
  RECV_REC = 'recv_rec',
  LONGEST_RECEPTION = 'longest_reception',
  RECV_TD = 'recv_td',
}

export enum HistoricalPerformanceMetrics {
  PAST_PERFORMANCE = 'pastPerformance',
  SIMILAR_DEFENSE = 'similarDefense',
  SIMILAR_COVERAGE = 'similarCoverage',
}
