/**
 * 🚨🚨🚨
 * DO NOT ADD OR EDIT THESE WITHOUT THE EXPLICT PERMISSION OF EITHER ANDY BREEN OR ETHAN HEFFLER
 * 🚨🚨🚨
 */

export type BorderRadiusType = typeof BorderRadius
export enum BorderRadius {
  roundedSm = 2,
  roundedDefault = 4,
  roundedMd = 8,
  roundedLg = 12,
  roundedXl = 16,
  roundedXXL = 20,
  roundedFull = 9999,
}
