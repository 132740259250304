export enum WeekGroup {
  PRE = 'PRE',
  REG = 'REG',
  POST = 'PO',
}

export function isValidWeekGroup(weekGroup: string | WeekGroup): weekGroup is WeekGroup {
  return Object.values(WeekGroup).includes(weekGroup as WeekGroup)
}

export enum WeekGroupValues {
  PRE = '-5,-4,-3,-2,-1',
  REG = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18',
  POST = '28,29,30,32',
}

export const weekGroupMap: Record<WeekGroup, WeekGroupValues> = {
  [WeekGroup.PRE]: WeekGroupValues.PRE,
  [WeekGroup.REG]: WeekGroupValues.REG,
  [WeekGroup.POST]: WeekGroupValues.POST,
}

export interface PremiumStatsPreviewKeyStatsParams {
  playerId: number
  league: string
  season: string
  weekGroup: WeekGroup
}

export const GRADE_AVERAGE_PRECISION = 10

export enum PreviewTypes {
  QB_PASSING_PREVIEW = 'QB_PASSING_PREVIEW',
  QB_RUSHING_PREVIEW = 'QB_RUSHING_PREVIEW',
  RB_RUSHING_PREVIEW = 'RB_RUSHING_PREVIEW',
  RB_RECEIVING_PREVIEW = 'RB_RECEIVING_PREVIEW',
  FB_RUSHING_PREVIEW = 'FB_RUSHING_PREVIEW',
  FB_RECEIVING_PREVIEW = 'FB_RECEIVING_PREVIEW',
  WR_RECEIVING_PREVIEW = 'WR_RECEIVING_PREVIEW',
  WR_RUSHING_PREVIEW = 'WR_RUSHING_PREVIEW',
  TE_RECEIVING_PREVIEW = 'TE_RECEIVING_PREVIEW',
  TE_RUSHING_PREVIEW = 'TE_RUSHING_PREVIEW',
  T_BLOCKING_PREVIEW = 'T_BLOCKING_PREVIEW',
  T_POSITIONAL_PIVOT_PREVIEW = 'T_POSITIONAL_PIVOT_PREVIEW',
  G_BLOCKING_PREVIEW = 'G_BLOCKING_PREVIEW',
  G_POSITIONAL_PIVOT_PREVIEW = 'G_POSITIONAL_PIVOT_PREVIEW',
  C_BLOCKING_PREVIEW = 'C_BLOCKING_PREVIEW',
  C_POSITIONAL_PIVOT_PREVIEW = 'C_POSITIONAL_PIVOT_PREVIEW',
  DI_DEFENSE_PREVIEW = 'DI_DEFENSE_PREVIEW',
  DI_SNAPS_PREVIEW = 'DI_SNAPS_PREVIEW',
  ED_DEFENSE_PREVIEW = 'ED_DEFENSE_PREVIEW',
  ED_SNAPS_PREVIEW = 'ED_SNAPS_PREVIEW',
  LB_DEFENSE_PREVIEW = 'LB_DEFENSE_PREVIEW',
  LB_SNAPS_PREVIEW = 'LB_SNAPS_PREVIEW',
  CB_DEFENSE_PREVIEW = 'CB_DEFENSE_PREVIEW',
  CB_SNAPS_PREVIEW = 'CB_SNAPS_PREVIEW',
  S_DEFENSE_PREVIEW = 'S_DEFENSE_PREVIEW',
  S_SNAPS_PREVIEW = 'S_SNAPS_PREVIEW',
  K_FIELD_GOALS_PREVIEW = 'K_FIELD_GOALS_PREVIEW',
  K_KICKING_PREVIEW = 'K_KICKING_PREVIEW',
  P_PUNTING_PREVIEW = 'P_PUNTING_PREVIEW',
  P_KICKING_PREVIEW = 'P_KICKING_PREVIEW',
}

export interface QbPassingPreview {
  previewType: PreviewTypes.QB_PASSING_PREVIEW
  stats: {
    dropbacks: number
    dropbacksPositionRank: number
    attempts: number
    attemptsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    interceptions: number
    interceptionsPositionRank: number
    avgDepthOfTarget: number
    avgDepthOfTargetPositionRank: number
    avgTimeToThrow: number
    avgTimeToThrowPositionRank: number
    pressureToSackRate: number
    pressureToSackRatePositionRank: number
    bigTimeThrows: number
    bigTimeThrowsPositionRank: number
    turnoverWorthyPlays: number
    turnoverWorthyPlaysPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offensive: number | null
    offensivePositionRank: number
    offensiveGradeAverage: number
    pass: number | null
    passPositionRank: number
    passGradeAverage: number
    positionRankTotal: number
  }
}

export interface QbRushingPreview {
  previewType: PreviewTypes.QB_RUSHING_PREVIEW
  stats: {
    attempts: number
    attemptsPositionRank: number
    designedYards: number
    designedYardsPositionRank: number
    runPlays: number
    runPlaysPositionRank: number
    scrambleYards: number
    scrambleYardsPositionRank: number
    scrambles: number
    scramblesPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    positionRankTotal: number
  }
  grades: {
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface RbRushingPreview {
  previewType: PreviewTypes.RB_RUSHING_PREVIEW
  stats: {
    attempts: number // carries
    attemptsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    ypa: number
    ypaPositionRank: number
    fumbles: number
    fumblesPositionRank: number
    avoidedTackles: number
    avoidedTacklesPositionRank: number
    yardsAfterContact: number
    yardsAfterContactPositionRank: number
    explosive: number
    explosivePositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface RbReceivingPreview {
  previewType: PreviewTypes.RB_RECEIVING_PREVIEW
  stats: {
    targets: number
    targetsPositionRank: number
    receptions: number
    receptionsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    drops: number
    dropsPositionRank: number
    avoidedTackles: number
    avoidedTacklesPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface FbRushingPreview {
  previewType: PreviewTypes.FB_RUSHING_PREVIEW
  stats: {
    attempts: number // carries
    attemptsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    ypa: number
    ypaPositionRank: number
    fumbles: number
    fumblesPositionRank: number
    avoidedTackles: number
    avoidedTacklesPositionRank: number
    yardsAfterContact: number
    yardsAfterContactPositionRank: number
    explosive: number
    explosivePositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface FbReceivingPreview {
  previewType: PreviewTypes.FB_RECEIVING_PREVIEW
  stats: {
    targets: number
    targetsPositionRank: number
    receptions: number
    receptionsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    drops: number
    dropsPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface WrReceivingPreview {
  previewType: PreviewTypes.WR_RECEIVING_PREVIEW
  stats: {
    targets: number
    targetsPositionRank: number
    receptions: number
    receptionsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    drops: number
    dropsPositionRank: number
    yardsPerReception: number
    yardsPerReceptionPositionRank: number
    yardsAfterCatch: number
    yardsAfterCatchPositionRank: number
    yardsAfterCatchPerReception: number
    yardsAfterCatchPerReceptionPositionRank: number
    targetedQbRating: number // passer rating when targeted
    targetedQbRatingPositionRank: number
    avgDepthOfTarget: number
    avgDepthOfTargetPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface WrRushingPreview {
  previewType: PreviewTypes.WR_RUSHING_PREVIEW
  stats: {
    attempts: number // carries
    attemptsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    ypa: number
    ypaPositionRank: number
    avoidedTackles: number
    avoidedTacklesPositionRank: number
    positionRankTotal: number
  }
  grades: {
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface TeReceivingPreview {
  previewType: PreviewTypes.TE_RECEIVING_PREVIEW
  stats: {
    targets: number
    targetsPositionRank: number
    receptions: number
    receptionsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    drops: number
    dropsPositionRank: number
    yardsPerReception: number
    yardsPerReceptionPositionRank: number
    yardsAfterCatch: number
    yardsAfterCatchPositionRank: number
    yardsAfterCatchPerReception: number
    yardsAfterCatchPerReceptionPositionRank: number
    targetedQbRating: number // passer rating when targeted
    targetedQbRatingPositionRank: number
    avgDepthOfTarget: number
    avgDepthOfTargetPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface TeRushingPreview {
  previewType: PreviewTypes.TE_RUSHING_PREVIEW
  stats: {
    attempts: number // carries
    attemptsPositionRank: number
    yards: number
    yardsPositionRank: number
    touchdowns: number
    touchdownsPositionRank: number
    ypa: number
    ypaPositionRank: number
    avoidedTackles: number
    avoidedTacklesPositionRank: number
    positionRankTotal: number
  }
  grades: {
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface TBlockingPreview {
  previewType: PreviewTypes.T_BLOCKING_PREVIEW
  stats: {
    snapCountsOffense: number // offensive snaps played
    snapCountsOffensePositionRank: number
    snapCountsPassPlay: number // pass block snaps played
    snapCountsPassPlayPositionRank: number
    snapCountsRunBlock: number // run block snaps played
    snapCountsRunBlockPositionRank: number
    penalties: number
    penaltiesPositionRank: number
    sacksAllowed: number
    sacksAllowedPositionRank: number
    hitsAllowed: number
    hitsAllowedPositionRank: number
    pressuresAllowed: number
    pressuresAllowedPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passBlock: number | null
    passBlockPositionRank: number
    passBlockGradeAverage: number
    runBlock: number | null
    runBlockPositionRank: number
    runBlockGradeAverage: number
    positionRankTotal: number
  }
}

export interface TPositionalPivotPreview {
  previewType: PreviewTypes.T_POSITIONAL_PIVOT_PREVIEW
  stats: {
    snapCountsLt: number
    snapCountsLtPositionRank: number
    snapCountsLg: number
    snapCountsLgPositionRank: number
    snapCountsCe: number
    snapCountsCePositionRank: number
    snapCountsRg: number
    snapCountsRgPositionRank: number
    snapCountsRt: number
    snapCountsRtPositionRank: number
    snapCountsTe: number
    snapCountsTePositionRank: number
    positionRankTotal: number
  }
}

export interface GBlockingPreview {
  previewType: PreviewTypes.G_BLOCKING_PREVIEW
  stats: {
    snapCountsOffense: number // offensive snaps played
    snapCountsOffensePositionRank: number
    snapCountsPassPlay: number // pass block snaps played
    snapCountsPassPlayPositionRank: number
    snapCountsRunBlock: number // run block snaps played
    snapCountsRunBlockPositionRank: number
    penalties: number
    penaltiesPositionRank: number
    sacksAllowed: number
    sacksAllowedPositionRank: number
    hitsAllowed: number
    hitsAllowedPositionRank: number
    pressuresAllowed: number
    pressuresAllowedPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passBlock: number | null
    passBlockPositionRank: number
    passBlockGradeAverage: number
    runBlock: number | null
    runBlockPositionRank: number
    runBlockGradeAverage: number
    positionRankTotal: number
  }
}

export interface GPositionalPivotPreview {
  previewType: PreviewTypes.G_POSITIONAL_PIVOT_PREVIEW
  stats: {
    snapCountsLt: number
    snapCountsLtPositionRank: number
    snapCountsLg: number
    snapCountsLgPositionRank: number
    snapCountsCe: number
    snapCountsCePositionRank: number
    snapCountsRg: number
    snapCountsRgPositionRank: number
    snapCountsRt: number
    snapCountsRtPositionRank: number
    snapCountsTe: number
    snapCountsTePositionRank: number
    positionRankTotal: number
  }
}

export interface CBlockingPreview {
  previewType: PreviewTypes.C_BLOCKING_PREVIEW
  stats: {
    snapCountsOffense: number // offensive snaps played
    snapCountsOffensePositionRank: number
    snapCountsPassPlay: number // pass block snaps played
    snapCountsPassPlayPositionRank: number
    snapCountsRunBlock: number // run block snaps played
    snapCountsRunBlockPositionRank: number
    penalties: number
    penaltiesPositionRank: number
    sacksAllowed: number
    sacksAllowedPositionRank: number
    hitsAllowed: number
    hitsAllowedPositionRank: number
    pressuresAllowed: number
    pressuresAllowedPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passBlock: number | null
    passBlockPositionRank: number
    passBlockGradeAverage: number
    runBlock: number | null
    runBlockPositionRank: number
    runBlockGradeAverage: number
    positionRankTotal: number
  }
}

export interface CPositionalPivotPreview {
  previewType: PreviewTypes.C_POSITIONAL_PIVOT_PREVIEW
  stats: {
    snapCountsLt: number
    snapCountsLtPositionRank: number
    snapCountsLg: number
    snapCountsLgPositionRank: number
    snapCountsCe: number
    snapCountsCePositionRank: number
    snapCountsRg: number
    snapCountsRgPositionRank: number
    snapCountsRt: number
    snapCountsRtPositionRank: number
    snapCountsTe: number
    snapCountsTePositionRank: number
    positionRankTotal: number
  }
}

export interface DiDefensePreview {
  previewType: PreviewTypes.DI_DEFENSE_PREVIEW
  stats: {
    tackles: number
    tacklesPositionRank: number
    assists: number
    assistsPositionRank: number
    forcedFumbles: number
    forcedFumblesPositionRank: number
    stops: number
    stopsPositionRank: number
    sacks: number
    sacksPositionRank: number
    hurries: number
    hurriesPositionRank: number
    hits: number
    hitsPositionRank: number
    totalPressures: number
    totalPressuresPositionRank: number
    battedPasses: number
    battedPassesPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface DiSnapsPreview {
  previewType: PreviewTypes.DI_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number
    snapCountsPassRushPositionRank: number
    positionRankTotal: number
  }
}

export interface EdDefensePreview {
  previewType: PreviewTypes.ED_DEFENSE_PREVIEW
  stats: {
    tackles: number
    tacklesPositionRank: number
    assists: number
    assistsPositionRank: number
    forcedFumbles: number
    forcedFumblesPositionRank: number
    stops: number
    stopsPositionRank: number
    sacks: number
    sacksPositionRank: number
    hurries: number
    hurriesPositionRank: number
    hits: number
    hitsPositionRank: number
    totalPressures: number
    totalPressuresPositionRank: number
    battedPasses: number
    battedPassesPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface EdSnapsPreview {
  previewType: PreviewTypes.ED_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number
    snapCountsPassRushPositionRank: number
    positionRankTotal: number
  }
}

export interface LbDefensePreview {
  previewType: PreviewTypes.LB_DEFENSE_PREVIEW
  stats: {
    tackles: number
    tacklesPositionRank: number
    assists: number
    assistsPositionRank: number
    forcedFumbles: number
    forcedFumblesPositionRank: number
    stops: number
    stopsPositionRank: number
    sacks: number
    sacksPositionRank: number
    missedTackles: number
    missedTacklesPositionRank: number
    qbRatingAgainst: number // passer rating allowed
    qbRatingAgainstPositionRank: number
    totalPressures: number
    totalPressuresPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    coverageDefense: number | null
    coverageDefensePositionRank: number
    coverageDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface LbSnapsPreview {
  previewType: PreviewTypes.LB_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number // total snaps
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number
    snapCountsPassRushPositionRank: number
    snapCountsCoverage: number
    snapCountsCoveragePositionRank: number
    positionRankTotal: number
  }
}

export interface CbDefensePreview {
  previewType: PreviewTypes.CB_DEFENSE_PREVIEW
  stats: {
    tackles: number
    tacklesPositionRank: number
    assists: number
    assistsPositionRank: number
    forcedFumbles: number
    forcedFumblesPositionRank: number
    stops: number
    stopsPositionRank: number
    sacks: number
    sacksPositionRank: number
    missedTackles: number
    missedTacklesPositionRank: number
    interceptions: number
    interceptionsPositionRank: number
    qbRatingAgainst: number // passer rating allowed
    qbRatingAgainstPositionRank: number
    yardsPerReception: number
    yardsPerReceptionPositionRank: number
    totalPressures: number
    totalPressuresPositionRank: number
    passBreakUps: number // PBUs
    passBreakUpsPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    coverageDefense: number | null
    coverageDefensePositionRank: number
    coverageDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface CbSnapsPreview {
  previewType: PreviewTypes.CB_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number // total snaps
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number
    snapCountsPassRushPositionRank: number
    snapCountsCoverage: number
    snapCountsCoveragePositionRank: number
    positionRankTotal: number
  }
}

export interface SDefensePreview {
  previewType: PreviewTypes.S_DEFENSE_PREVIEW
  stats: {
    tackles: number
    tacklesPositionRank: number
    assists: number
    assistsPositionRank: number
    forcedFumbles: number
    forcedFumblesPositionRank: number
    stops: number
    stopsPositionRank: number
    sacks: number
    sacksPositionRank: number
    missedTackles: number
    missedTacklesPositionRank: number
    interceptions: number
    interceptionsPositionRank: number
    qbRatingAgainst: number // passer rating allowed
    qbRatingAgainstPositionRank: number
    yardsPerReception: number
    yardsPerReceptionPositionRank: number
    totalPressures: number
    totalPressuresPositionRank: number
    passBreakUps: number // PBUs
    passBreakUpsPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    coverageDefense: number | null
    coverageDefensePositionRank: number
    coverageDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface SSnapsPreview {
  previewType: PreviewTypes.S_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number // total snaps
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number
    snapCountsPassRushPositionRank: number
    snapCountsCoverage: number
    snapCountsCoveragePositionRank: number
    positionRankTotal: number
  }
}

export interface KFieldGoalsPreview {
  previewType: PreviewTypes.K_FIELD_GOALS_PREVIEW
  stats: {
    totalAttempts: number
    totalAttemptsPositionRank: number
    totalMade: number
    totalMadePositionRank: number
    fiftyAttempts: number
    fiftyAttemptsPositionRank: number
    fiftyMade: number
    fiftyMadePositionRank: number
    patAttempts: number
    patAttemptsPositionRank: number
    patMade: number
    patMadePositionRank: number
    positionRankTotal: number
  }
  grades: {
    fgepKicker: number | null
    fgepKickerPositionRank: number
    fgepKickerGradeAverage: number
    positionRankTotal: number
  }
}

export interface KKickingPreview {
  previewType: PreviewTypes.K_KICKING_PREVIEW
  stats: {
    kicksReturned: number
    kicksReturnedPositionRank: number
    percentReturned: number // kickoff return %
    percentReturnedPositionRank: number
    averageYardsPerReturn: number // yards per return
    averageYardsPerReturnPositionRank: number
    averageStartingFieldPosition: number // average field position
    averageStartingFieldPositionPositionRank: number
    positionRankTotal: number
  }
  grades: {
    kickoffKicker: number | null
    kickoffKickerPositionRank: number
    kickoffKickerGradeAverage: number
    positionRankTotal: number
  }
}

export interface PPuntingPreview {
  previewType: PreviewTypes.P_PUNTING_PREVIEW
  stats: {
    attempts: number // punts
    attemptsPositionRank: number
    averageNetYards: number // punt gross avg
    averageNetYardsPositionRank: number
    long: number
    longPositionRank: number
    insideTwenties: number // pinned inside the 20
    insideTwentiesPositionRank: number
    percentReturned: number // % return
    percentReturnedPositionRank: number
    averageYardsPerReturn: number // yards per return
    averageYardsPerReturnPositionRank: number
    touchbacks: number
    touchbacksPositionRank: number
    downeds: number // downed
    downedsPositionRank: number
    outOfBounds: number
    outOfBoundsPositionRank: number
    fairCatches: number
    fairCatchesPositionRank: number
    averageHangtime: number
    averageHangtimePositionRank: number
    positionRankTotal: number
  }
  grades: {
    punter: number | null
    punterPositionRank: number
    punterGradeAverage: number
    positionRankTotal: number
  }
}

export interface PKickingPreview {
  previewType: PreviewTypes.P_KICKING_PREVIEW
  stats: {
    kicksReturned: number
    kicksReturnedPositionRank: number
    percentReturned: number // kickoff return %
    percentReturnedPositionRank: number
    averageYardsPerReturn: number // yards per return
    averageYardsPerReturnPositionRank: number
    averageStartingFieldPosition: number // average field position
    averageStartingFieldPositionPositionRank: number
    positionRankTotal: number
  }
  grades: {
    kickoffKicker: number | null
    kickoffKickerPositionRank: number
    kickoffKickerGradeAverage: number
    positionRankTotal: number
  }
}

type PrimaryStatsPreview =
  | QbPassingPreview
  | RbRushingPreview
  | FbRushingPreview
  | WrReceivingPreview
  | TeReceivingPreview
  | TBlockingPreview
  | GBlockingPreview
  | CBlockingPreview
  | DiDefensePreview
  | EdDefensePreview
  | LbDefensePreview
  | CbDefensePreview
  | SDefensePreview
  | KFieldGoalsPreview
  | PPuntingPreview
  | null // Not enough snaps

type SecondaryStatsPreview =
  | QbRushingPreview
  | RbReceivingPreview
  | FbReceivingPreview
  | WrRushingPreview
  | TeRushingPreview
  | TPositionalPivotPreview
  | GPositionalPivotPreview
  | CPositionalPivotPreview
  | DiSnapsPreview
  | EdSnapsPreview
  | LbSnapsPreview
  | CbSnapsPreview
  | SSnapsPreview
  | KKickingPreview
  | PKickingPreview
  | null // Not enough snaps

export interface PremiumStatsPreviewKeyStatsResponse {
  primaryStats: PrimaryStatsPreview
  primaryUrl: string
  secondaryStats: SecondaryStatsPreview
  secondaryUrl: string
}
