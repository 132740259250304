import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  SubscriptionPlanType,
  SubscriptionPlans,
  WindowDimensionsType,
  CheckoutResponseError,
} from '@pff-consumer/schema'
import { RecurlyError } from '@recurly/recurly-js'
import { isValidEmail } from '@pff-consumer/utils'
import { getBreakpointRange } from '@pff-consumer/core-ui'

export interface CheckoutState {
  checkoutError?: CheckoutResponseError
  email: string
  grootUid: string | undefined
  emailErrorMessage: string | undefined
  hasOptedForNewsLetter: boolean
  paymentError?: RecurlyError
  promoCode: string
  selectedPlan: SubscriptionPlanType
  windowDimensions: WindowDimensionsType
  monthlyPlan: SubscriptionPlans
  variant: string
  expirationDate: string
  isCancelSubscriptionModalOpen: boolean
  subscriptionPlan: string
}
export const CHECKOUT_STATE_KEY = 'checkout'

export const checkoutAdapter = createEntityAdapter<CheckoutState>()
export const initialCheckoutState: CheckoutState = checkoutAdapter.getInitialState({
  selectedPlan: SubscriptionPlans.PREMIUM_ANNUAL, // Temporarily disable random plan selection
  promoCode: '',
  windowDimensions: { width: 920, height: 600 },
  email: '',
  grootUid: undefined,
  emailErrorMessage: '',
  hasOptedForNewsLetter: true,
  monthlyPlan: SubscriptionPlans.PREMIUM_MONTHLY_SEASON,
  variant: '',
  expirationDate: '',
  isCancelSubscriptionModalOpen: false,
  subscriptionPlan: '',
})

export const checkoutSlice = createSlice({
  name: CHECKOUT_STATE_KEY,
  initialState: initialCheckoutState,
  reducers: {
    updateSelectedPlan: (state: CheckoutState, { payload }: { payload: SubscriptionPlanType }) => {
      state.selectedPlan = payload
    },
    updatePromoCode: (state: CheckoutState, { payload }: { payload: string }) => {
      state.promoCode = payload
    },
    updateDimensions: (state: CheckoutState, { payload }: { payload: WindowDimensionsType }) => {
      state.windowDimensions = payload
    },
    setDefaultEmail: (state: CheckoutState, { payload }: { payload: string | undefined }) => {
      state.email = payload || ''
    },
    updateEmail: (state: CheckoutState, { payload }: { payload: string }) => {
      state.email = payload
    },
    updateCheckoutError: (state: CheckoutState, { payload }: { payload: CheckoutResponseError }) => {
      state.checkoutError = payload
    },
    updatePaymentError: (state: CheckoutState, { payload }: { payload: RecurlyError }) => {
      state.paymentError = payload
    },
    clearPaymentError: (state: CheckoutState) => {
      delete state.paymentError
    },
    validateEmail(state: CheckoutState) {
      const email = state.email || ''
      let emailErrorMessage = ''
      if (email === '') {
        emailErrorMessage = 'Email is required'
      } else if (!isValidEmail(email)) {
        emailErrorMessage = 'Please enter a valid email'
      }
      state.emailErrorMessage = emailErrorMessage
    },
    setGrootUid: (state: CheckoutState, { payload }: { payload: string | undefined }) => {
      state.grootUid = payload || ''
    },
    toggleNewsLetterPreference: (state: CheckoutState) => {
      state.hasOptedForNewsLetter = !state.hasOptedForNewsLetter
    },
    setNewsLetterPreference: (state: CheckoutState, { payload }: { payload: boolean }) => {
      state.hasOptedForNewsLetter = payload
    },
    updateMonthlyPlan: (state: CheckoutState, { payload }: { payload: SubscriptionPlans }) => {
      state.monthlyPlan = payload
    },
    updateVariant: (state: CheckoutState, { payload }: { payload: string }) => {
      state.variant = payload
    },
    updateExpirationDate: (state: CheckoutState, { payload }: { payload: string }) => {
      state.expirationDate = payload
    },
    updateIsCancelSubscriptionModalOpen: (state: CheckoutState, { payload }: { payload: boolean }) => {
      state.isCancelSubscriptionModalOpen = payload
    },
    updateSubscriptionPlan: (state: CheckoutState, { payload }: { payload: string }) => {
      state.subscriptionPlan = payload
    },
  },
})

export const checkoutReducer = checkoutSlice.reducer
export const checkoutActions = checkoutSlice.actions

export const getCheckoutState = (rootState: { [x: string]: CheckoutState }): CheckoutState => {
  return rootState[CHECKOUT_STATE_KEY]
}

export const checkoutSelectors = {
  getSelectedPlan: createSelector(getCheckoutState, (state: CheckoutState) => state.selectedPlan),
  getPromoCode: createSelector(getCheckoutState, (state: CheckoutState) => state.promoCode),
  getWindowDimensions: createSelector(getCheckoutState, (state: CheckoutState) => state.windowDimensions),
  getBreakpoints: createSelector(getCheckoutState, (state: CheckoutState) => {
    const { width } = state.windowDimensions
    return {
      isDesktop: width >= getBreakpointRange('lg').min,
      isTablet: width >= getBreakpointRange('md').min && width < getBreakpointRange('lg').min,
      isMobile: width < getBreakpointRange('md').min,
    }
  }),
  getEmail: createSelector(getCheckoutState, (state: CheckoutState) => state.email),
  getGrootUid: createSelector(getCheckoutState, (state: CheckoutState) => state.grootUid),
  getEmailErrorMessage: createSelector(getCheckoutState, (state: CheckoutState) => state.emailErrorMessage),
  getIsEmailValid: createSelector(getCheckoutState, (state: CheckoutState) => state.email && !state.emailErrorMessage),
  getNewsLetterPreference: createSelector(getCheckoutState, (state: CheckoutState) => state.hasOptedForNewsLetter),
  getPaymentError: createSelector(getCheckoutState, (state: CheckoutState) => state.paymentError),
  getCheckoutError: createSelector(getCheckoutState, (state: CheckoutState) => state.checkoutError),
  getMonthlyPlan: createSelector(getCheckoutState, (state: CheckoutState) => state.monthlyPlan),
  getVariant: createSelector(getCheckoutState, (state: CheckoutState) => state.variant),
  getExpirationDate: createSelector(getCheckoutState, (state: CheckoutState) => state.expirationDate),
  getIsCancelSubscriptionModalOpen: createSelector(
    getCheckoutState,
    (state: CheckoutState) => state.isCancelSubscriptionModalOpen
  ),
  getSubscriptionPlan: createSelector(getCheckoutState, (state: CheckoutState) => state.subscriptionPlan),
}
