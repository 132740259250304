import {
  LiveDraftPick,
  LiveDraftStatusResponse,
  LiveDraftStatusYahooOauthErrorResponse,
} from '@pff-consumer/schema/fantasy-live-draft/picks'
import { envConfig } from '@pff-consumer/utils'
import { Socket } from '../socket'

const { WS_URL, CONSUMER_API_KEY } = envConfig

interface LiveDraftSocketArgs {
  authToken: string
  leagueId: number
  onConnect?: () => void
  onClose?: (event: Event & { code?: number; reason?: string }) => void
  onMessage?: (message: LiveDraftPick | LiveDraftStatusResponse | LiveDraftStatusYahooOauthErrorResponse) => void
  onError?: (event: Event) => void
}

export const LiveDraftSocket = ({
  authToken,
  leagueId,
  onConnect,
  onClose,
  onMessage,
  onError,
}: LiveDraftSocketArgs) => {
  const socket = new Socket()
  socket.connect(`${WS_URL}/live-draft?api_key=${CONSUMER_API_KEY}&authorization=${authToken}`)
  socket.on('open', (event: Event) => {
    console.debug('on open', event)
    socket.send({
      action: 'OnConnectCallback',
      payload: {
        leagueId,
      },
    })
    if (onConnect) {
      onConnect()
    }
  })

  socket.on('message', (event: MessageEvent) => {
    console.debug('on message', event.data)
    if (onMessage) {
      onMessage(JSON.parse(event.data))
    }
  })

  socket.on('close', (event: CloseEvent) => {
    console.debug('on close', event)
    if (onClose) {
      onClose(event)
    }
  })

  socket.on('error', (event: Event) => {
    console.error(event)
    if (onError) {
      onError(event)
    }
  })
}
