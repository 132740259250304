import FuzzySearch from 'fuzzy-search'
import { RankingsType, type WeeklyRankingsEntity } from '@pff-consumer/schema'
import type { RankingsEntity } from '../fantasy.slice'

type PlayerEntity = RankingsEntity | WeeklyRankingsEntity

export const getPlayersFilteredByName = (players: PlayerEntity[], searchTerm: string, type: RankingsType) => {
  // Create new searcher whenever the data changes
  const fieldsToSearch =
    type === RankingsType.WEEKLY
      ? ['first_name', 'last_name']
      : ['first_name', 'last_name', 'long_name', 'teamName', 'teamCity']

  const fuzzySearcher = new FuzzySearch(players || [], fieldsToSearch, {
    caseSensitive: false,
  })

  // Stop fuzzy searching breaking when users use apostrophes that are in players names, so strip them out and fuzzy will still work
  const prunedSearchTerm = searchTerm.replaceAll('’', '').replaceAll(`'`, '').replaceAll('`', '')
  const filteredResults = fuzzySearcher.search(prunedSearchTerm)
  return filteredResults
}
