import { AnalyticsEventProperties } from './analytics-types'
import { HeapEventProperties } from './heap-types'

const stripUndefined = (object: any) => {
  const objectWithoutUndefinedKeys = object

  Object.keys(objectWithoutUndefinedKeys).forEach((key) => {
    if (objectWithoutUndefinedKeys[key] === undefined) delete objectWithoutUndefinedKeys[key]
  })

  return objectWithoutUndefinedKeys
}

export const mapAnalyticsEventPropertiesForHeap = (
  eventProperties: Partial<AnalyticsEventProperties>
): Partial<HeapEventProperties> => {
  const heapEventProperties: HeapEventProperties = {
    author: eventProperties.author,
    category: eventProperties.category,
    created_timestamp: eventProperties.createdTimestamp,
    element_location: eventProperties.elementLocation,
    element_name: eventProperties.elementName,
    cta_name: eventProperties.ctaName,
    cta_location: eventProperties.ctaLocation,
    experience: eventProperties.experience,
    premium: eventProperties.premium,
    headline: eventProperties.headline,
    hyperlink_path: eventProperties.hyperlinkPath,
    screen_name: eventProperties.screenName,
    url: eventProperties.url,
    pff_user_id: eventProperties.pffUserId,
    logged_in: eventProperties.loggedIn,
    notifications_enabled: eventProperties.isNotificationsEnabled,
    subscription_status: eventProperties.subscriptionStatus,
    subscription_type: eventProperties.subscriptionType,
    subscription_plan_type: eventProperties.subscriptionPlanType,
    price: eventProperties.price,
    trial_included: eventProperties.isTrialIncluded,
    in_trial: eventProperties.inTrial,
    device: eventProperties.device,
    filtered_view: eventProperties.filteredView,
    filter_category: eventProperties.filterCategory,
    location_country: eventProperties.locationCountry,
    article_id: eventProperties.article_id,
    bookmarked: eventProperties.bookmarked,
    location_opened: eventProperties.locationOpened,
    number_articles_bookmarked: eventProperties.numberArticlesBookmarked,
    // location_state: eventProperties.locationState,
    // location_city: eventProperties.locationCity,
    version: eventProperties.version,
    previous_subscription_type: eventProperties.previousSubscriptionType,
    payment_method: eventProperties.paymentMethod,
    payment_failed: eventProperties.paymentFailed,
    method: eventProperties.method,
    account_method: eventProperties.accountMethod,
    length: eventProperties.length,
    bet_category: eventProperties.bet_category,
    general_bet_type: eventProperties.general_bet_type,
    detailed_bet_type: eventProperties.detailed_bet_type,
    bet_money_line: eventProperties.bet_money_line,
    bet_grade: eventProperties.bet_grade,
    team: eventProperties.team,
    game_week: eventProperties.game_week,
    game_time: eventProperties.game_time,
    season_year: eventProperties.season_year,
    analysis_type: eventProperties.analysis_type,
    analysis_type_card_number: eventProperties.analysis_type_card_number,
    touch_point: eventProperties.touch_point,
    viewed_from: eventProperties.viewed_from,
    home_team: eventProperties.home_team,
    away_team: eventProperties.away_team,
    bet_count: eventProperties.bet_count,
    bet_category_count: eventProperties.bet_category_count,
    general_bet_type_count: eventProperties.general_bet_type_count,
    detailed_bet_type_count: eventProperties.detailed_bet_type_count,
    game_id_count: eventProperties.game_id_count,
    team_count: eventProperties.team_count,
    sportsbook: eventProperties.sportsbook,
    welcome_modal_screen_number: eventProperties.welcome_modal_screen_number,
    screen_scroll_button_was_clicked_on: eventProperties.screen_scroll_button_was_clicked_on,
    location: eventProperties.location,
    sort_field: eventProperties.sort_field,
    sort_direction: eventProperties.sort_direction,
    sort_ranker: eventProperties.sort_ranker,
    sort_scoring: eventProperties.sort_scoring,
    sort_league_type: eventProperties.sort_league_type,
    filter_position: eventProperties.filter_position,
    filter_tags: eventProperties.filter_tags,
    player_name: eventProperties.player_name,
    player_one_name: eventProperties.player_one_name,
    player_two_name: eventProperties.player_two_name,
    player_rank: eventProperties.player_rank,
    player_adp_rank: eventProperties.player_adp_rank,
    is_player_one: eventProperties.isPlayer1,
    player_projection_rank: eventProperties.player_projection_rank,
    player_tags: eventProperties.player_tags,
    fantasy_expert_ranker: eventProperties.fantasy_expert_ranker,
    article_locked: eventProperties.articleLocked,
    article_type: eventProperties.articleType,
    player_headshot_location: eventProperties.player_headshot_location,
    application_installed_at: eventProperties.firstLaunchTimestamp,
    days_since_install: eventProperties.daysSinceInstall,
    first_7_days: eventProperties.first7Days,
    first_14_days: eventProperties.first14Days,
    first_21_days: eventProperties.first21Days,
    variant: eventProperties.variant,
    time_to_respond: eventProperties.timeToRespond,
    question_id: eventProperties.questionId,
    question: eventProperties.question,
    answer: eventProperties.answer,
    answer_order: eventProperties.answerOrder,
    skipped: eventProperties.skipped,
    week: eventProperties.week,
    season: eventProperties.season,
    matchup_player: eventProperties.matchupPlayer,
    matchup_selected: eventProperties.matchupSelected,
    matchup_selected_position: eventProperties.matchupSelectedPosition,
    position: eventProperties.position,
    event_id: eventProperties.event_id,
    type: eventProperties.type,
    analysis_text: eventProperties.analysis_text,
    locked: eventProperties.locked,
    experiment: eventProperties.experiment,
    player: eventProperties.player,
    bet_location: eventProperties.bet_location,
    card_name: eventProperties.cardName,
    card_location: eventProperties.cardLocation,
    team_id: eventProperties.teamId,
    email: eventProperties.email,
    error_type: eventProperties.error_type,
    error: eventProperties.error,
    error_source: eventProperties.error_source,
    card_swiped: eventProperties.cardSwiped,
    swipe_direction: eventProperties.swipeDirection,
    path: eventProperties.path,
    team_abv: eventProperties.teamAbv,
    simulator_cta: eventProperties.simulatorCta,
    show_all: eventProperties.showAll,
    player_type: eventProperties.playerType,
    player_position: eventProperties.playerPosition,
    round: eventProperties.round,
    pick_score: eventProperties.pick_score,
    source: eventProperties.source,
    league_site: eventProperties.leagueSite,
    league_name: eventProperties.leagueName,
    league_created_at: eventProperties.leagueCreatedAt,
    league_type: eventProperties.leagueType,
    lineup_type: eventProperties.lineupType,
    draft_type: eventProperties.draftType,
    league_scoring_type: eventProperties.leagueScoringType,
    keepers: eventProperties.keepers,
    league_sync_step: eventProperties.leagueSyncStep,
    tool: eventProperties.tool,
    platform_name: eventProperties.platformName,
    number_of_teams: eventProperties.numberOfTeams,
    your_draft_pick_number: eventProperties.yourDraftPickNumber,
    number_of_draft_rounds: eventProperties.numberOfDraftRounds,
    starting_position_count_QB: eventProperties.startingPositionCountQB,
    starting_position_count_RB: eventProperties.startingPositionCountRB,
    starting_position_count_WR: eventProperties.startingPositionCountWR,
    starting_position_count_TE: eventProperties.startingPositionCountTE,
    starting_position_count_flex: eventProperties.startingPositionCountFlex,
    starting_position_count_super_flex: eventProperties.startingPositionCountSuperFlex,
    starting_position_count_DST: eventProperties.startingPositionCountDST,
    starting_position_count_K: eventProperties.startingPositionCountK,
    completed_draft_grade: eventProperties.completedDraftGrade,
    player_pick_grade: eventProperties.player_pick_grade,
    player_id: eventProperties.player_id,
    suggested_player: eventProperties.suggested_player,
    overall_pick_number: eventProperties.overall_pick_number,
    draft_round: eventProperties.draft_round,
    search_term: eventProperties.search_term,
    filter_type: eventProperties.filter_type,
    filter_applied: eventProperties.filter_applied,
    toggle_status: eventProperties.toggle_status || eventProperties.toggleStatus,
    drafted_player_league_id: eventProperties.drafted_player_league_id,
    drafted_player_provider: eventProperties.drafted_player_provider,
    drafted_player_id: eventProperties.drafted_player_id,
    player_modal_location: eventProperties.player_modal_location,
    player_modal_player_id: eventProperties.player_modal_player_id,
    player_modal_player_name: eventProperties.player_modal_player_name,
    num_leagues_synced: eventProperties.numLeaguesSynced,
    league_optimized: eventProperties.leagueOptimized,
    toggle_locked: eventProperties.toggleLocked,
    subscription_plan: eventProperties.subscriptionPlan,
    survey_answer: eventProperties.surveyAnswer,
    num_renewals: eventProperties.num_renewals,
    drafted_player_name: eventProperties.drafted_player_name,
    drafted_player_position: eventProperties.drafted_player_position,
    drafted_player_pick_grade: eventProperties.drafted_player_pick_grade,
    player_suggested: eventProperties.player_suggested,
    selected_player_number: eventProperties.selected_player_number,
    stat_key_selection: eventProperties.statKeySelection,
    draft_strategy: eventProperties.draftStrategy,
    grade: eventProperties.grade,
    number_of_bench_spots: eventProperties.numberOfBenchSpots,
    opponent_strategy_favorite_teams: eventProperties.opponentStrategyFavoriteTeams,
    opponent_strategy_avoided_teams: eventProperties.opponentStrategyAvoidedTeams,
    has_keepers: eventProperties.hasKeepers,
    draft_status: eventProperties.draftStatus,
    availability_percent: eventProperties.availabilityPercent,
    round_completion_rate: eventProperties.roundCompletionRate,
    in_season: eventProperties.inSeason,
    anchors: eventProperties.anchors,
    past_performance_toggle: eventProperties.pastPerformanceToggle,
    past_performance_stat: eventProperties.pastPerformanceStat,
    similar_player_id: eventProperties.similarPlayerId,
    similar_player_name: eventProperties.similarPlayerName,
    similar_player_position: eventProperties.similarPlayerPosition,
    similar_player_team: eventProperties.similarPlayerTeamAbv,
    previous_league_type: eventProperties.previous_league_type,
    modal_location: eventProperties.modal_location,
    modal_type: eventProperties.modal_type,
    chrome_extension_version: eventProperties.chrome_extension_version,
    league_id: eventProperties.league_id,
    error_message: eventProperties.error_message,
    error_code: eventProperties.error_code,
  }
  return stripUndefined(heapEventProperties)
}
