import { FONT_FAMILY_SANS, FONT_FAMILY_SERIF, FONT_FAMILY_INTER, PRIMARY_FONT, Fonts } from '@pff-consumer/core-ui'
import type { PffFontFamilyDEPRECATED, FontWeight, FontStyle, InterFontFamilyDEPRECATED } from '@pff-consumer/core-ui'
import type { Styles } from 'react-jss'

/**
 * This is not a hook. We should rename this at some point.
 */
export const useFontStyles = (
  family: Fonts | PffFontFamilyDEPRECATED | InterFontFamilyDEPRECATED,
  weight: FontWeight = 'normal',
  style: FontStyle = 'normal'
): Styles => {
  return {
    fontFamily: family,
    fontWeight: weight,
    fontStyle: style,
  }
}

/**
 * JSS
 * https://cssinjs.org/jss-syntax/?v=v10.9.1-alpha.2#font-face
 */
export const generateWebFontFaces = (fontUrlPath: string): Styles => ({
  '@font-face': [
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-Italic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSerif-Italic.woff') format('woff')`] },
      fontWeight: 'normal',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-Bold.woff2') format('woff2')`,
      fallbacks: {
        src: [`url('${fontUrlPath}/PFFSerif-Bold.woff') format('woff')`],
      },
      fontWeight: '700',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-BoldItalic.woff2') format('woff2')`,
      fallbacks: {
        src: [`url('${fontUrlPath}/PFFSerif-BoldItalic.woff') format('woff')`],
      },
      fontWeight: '700',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-Medium.woff2') format('woff2')`,
      fallbacks: {
        src: [`url('${fontUrlPath}/PFFSerif-Medium.woff') format('woff')`],
      },
      fontWeight: '500',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-MediumItalic.woff2') format('woff2')`,
      fallbacks: {
        src: [`url('${fontUrlPath}/PFFSerif-MediumItalic.woff') format('woff')`],
      },
      fontWeight: '500',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-SemiBold.woff2') format('woff2')`,
      fallbacks: {
        src: [`url('${fontUrlPath}/PFFSerif-SemiBold.woff') format('woff')`],
      },
      fontWeight: '600',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-SemiBoldItalic.woff2') format('woff2')`,
      fallbacks: {
        src: [`url('${fontUrlPath}/PFFSerif-SemiBoldItalic.woff') format('woff')`],
      },
      fontWeight: '600',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SERIF,
      src: `url('${fontUrlPath}/PFFSerif-Regular.woff2') format('woff2')`,
      fallbacks: {
        src: [`url('${fontUrlPath}/PFFSerif-Regular.woff') format('woff')`],
      },
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-BoldItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-BoldItalic.woff') format('woff')`] },
      fontWeight: '700',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-LightItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-LightItalic.woff') format('woff')`] },
      fontWeight: '300',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-Medium.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-Medium.woff') format('woff')`] },
      fontWeight: '500',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-Light.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-Light.woff') format('woff')`] },
      fontWeight: '300',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-Italic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-Italic.woff') format('woff')`] },
      fontWeight: 'normal',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-SemiBoldItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-SemiBoldItalic.woff') format('woff')`] },
      fontWeight: '600',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-Bold.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-Bold.woff') format('woff')`] },
      fontWeight: '700',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-MediumItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-MediumItalic.woff') format('woff')`] },
      fontWeight: '500',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-ExtraBoldItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-ExtraBoldItalic.woff') format('woff')`] },
      fontWeight: '800',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-ExtraBold.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-ExtraBold.woff') format('woff')`] },
      fontWeight: '800',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-Thin.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-Thin.woff') format('woff')`] },
      fontWeight: '100',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-SemiBold.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-SemiBold.woff') format('woff')`] },
      fontWeight: '600',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-ThinItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-ThinItalic.woff') format('woff')`] },
      fontWeight: '100',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_SANS,
      src: `url('${fontUrlPath}/PFFSans-Regular.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/PFFSans-Regular.woff') format('woff')`] },
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-BoldItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-BoldItalic.woff') format('woff')`] },
      fontWeight: '700',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-LightItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-LightItalic.woff') format('woff')`] },
      fontWeight: '300',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-Medium.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-Medium.woff') format('woff')`] },
      fontWeight: '500',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-Light.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-Light.woff') format('woff')`] },
      fontWeight: '300',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-Italic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-Italic.woff') format('woff')`] },
      fontWeight: 'normal',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-SemiBoldItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-SemiBoldItalic.woff') format('woff')`] },
      fontWeight: '600',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-Bold.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-Bold.woff') format('woff')`] },
      fontWeight: '700',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-MediumItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-MediumItalic.woff') format('woff')`] },
      fontWeight: '500',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-ExtraBoldItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-ExtraBoldItalic.woff') format('woff')`] },
      fontWeight: '700',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-ExtraBold.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-ExtraBold.woff') format('woff')`] },
      fontWeight: '700',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-Thin.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-Thin.woff') format('woff')`] },
      fontWeight: '100',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-SemiBold.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-SemiBold.woff') format('woff')`] },
      fontWeight: '600',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-ThinItalic.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-ThinItalic.woff') format('woff')`] },
      fontWeight: '100',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: FONT_FAMILY_INTER,
      src: `url('${fontUrlPath}/Inter-Regular.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Inter-Regular.woff') format('woff')`] },
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: PRIMARY_FONT,
      src: `url('${fontUrlPath}/Avenir-Black.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Avenir-Black.woff') format('woff')`] },
      fontWeight: '800',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: PRIMARY_FONT,
      src: `url('${fontUrlPath}/Avenir-BlackOblique.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Avenir-BlackOblique.woff') format('woff')`] },
      fontWeight: '800',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: PRIMARY_FONT,
      src: `url('${fontUrlPath}/Avenir-Heavy.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Avenir-Heavy.woff') format('woff')`] },
      fontWeight: '900',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
    {
      fontFamily: PRIMARY_FONT,
      src: `url('${fontUrlPath}/Avenir-HeavyOblique.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Avenir-HeavyOblique.woff') format('woff')`] },
      fontWeight: '900',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: PRIMARY_FONT,
      src: `url('${fontUrlPath}/Avenir-Oblique.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Avenir-Oblique.woff') format('woff')`] },
      fontWeight: 'normal',
      fontStyle: 'italic',
      fontDisplay: 'block',
    },
    {
      fontFamily: PRIMARY_FONT,
      src: `url('${fontUrlPath}/Avenir-Roman.woff2') format('woff2')`,
      fallbacks: { src: [`url('${fontUrlPath}/Avenir-Roman.woff') format('woff')`] },
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontDisplay: 'block',
    },
  ],
})
