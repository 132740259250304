import { LeagueType, DraftType, HostedSites } from './league'
import { LeagueSettingsPositionSettings } from './league-settings'

export interface ThirdPartyScraperResult {
  name: string
  size: number
  auction: boolean
  draft_type: DraftType
  drafters: string[]
  auction_budget: number | null
  position_settings: LeagueSettingsPositionSettings
  type: LeagueType
  site: HostedSites
  site_url: string
  scoring_settings: string // json stringified
  time_per_pick: number
  league_type: LeagueType
  season: number
  hosted_site: HostedSites
  hosted_site_url: string
  hosted_league_id: number | null
  keepers: number
  team_name: string | null
  draft_date: string | null
  hosted_owner_id: string
}

/*
  It would be great if we didn't have to return these error codes from the scraper responses, then
  we could expect the scrapper calls to return success or null. This would simplify our code by not
  forcing us to check which properties exist in the response to determine its type. But unfortunately
  Pylon uses the conditional response errorCodes to return site specific unsupported league errors. We
  should revisit this once we have more of the scrapers in place and see if we can move the unsupported
  league indicator into the site classes and handle them inside the service layer.

  the yahoo_named_league errorCode is a good example of how we can fix this.
*/
export interface ThirdPartyScraperError {
  errorCode: string | 'unsupported_league_custom'
}

export interface MatchupOutcome {
  wins: number
  losses: number
  ties: number
}

export enum SleeperLeagueTypes {
  REDRAFT = 0,
  KEEPER = 1,
  DYNASTY = 2,
}

export enum SleeperDraftTypes {
  SNAKE = 'snake',
  LINEAR = 'linear',
  AUCTION = 'auction',
}

export enum SleeperDraftPlayerTypes {
  ALL = 0,
  ROOKIES = 1,
  VETS = 2,
}

export enum SleeperStatusTypes {
  PRE_DRAFT = 'pre_draft',
  DRAFTING = 'drafting',
  IN_SEASON = 'in_season',
  POST_SEASON = 'post_season',
  COMPLETE = 'complete',
}

export type SleeperLeagueType = (typeof SleeperLeagueTypes)[keyof typeof SleeperLeagueTypes]
export type SleeperDraftType = `${SleeperDraftTypes}`
export type SleeperDraftPlayerType = (typeof SleeperDraftPlayerTypes)[keyof typeof SleeperDraftPlayerTypes]
export type SleeperStatusType = `${SleeperStatusTypes}`

export interface SleeperLeagueSettings {
  max_keepers: number
  type: (typeof SleeperLeagueTypes)[keyof typeof SleeperLeagueTypes]
}

export interface SleeperScoreSettings {
  idp_tkl_ast: number
  st_ff: number
  idp_ff: number
  pts_allow_7_13: number
  pass_inc: number
  def_st_ff: number
  fum_ret_yd: number
  bonus_fd_rb: number
  bonus_fd_te: number
  rush_td_40p: number
  rec_yd: number
  fum_rec_td: number
  bonus_rush_rec_yd_200: number
  pts_allow_35p: number
  pts_allow_28_34: number
  fum: number
  pr_yd: number
  rush_40p: number
  bonus_rec_rb: number
  yds_allow_0_100: number
  rush_yd: number
  bonus_fd_qb: number
  idp_qb_hit: number
  bonus_def_int_td_50p: number
  yds_allow_100_199: number
  bonus_rush_att_20: number
  tkl_solo: number
  pass_td: number
  blk_kick: number
  rec_td_50p: number
  fgmiss_40_49: number
  pass_yd: number
  safe: number
  bonus_rush_yd_200: number
  rec_td_40p: number
  pass_fd: number
  idp_fum_rec: number
  def_td: number
  bonus_tkl_10p: number
  fgm_50p: number
  pass_cmp_40p: number
  tkl: number
  def_st_td: number
  bonus_rec_yd_200: number
  rec_10_19: number
  idp_pass_def: number
  fum_rec: number
  yds_allow_300_349: number
  def_pass_def: number
  rush_2pt: number
  tkl_loss: number
  pass_cmp: number
  fgmiss_0_19: number
  def_3_and_out: number
  rec_40p: number
  pass_sack: number
  pass_td_50p: number
  xpm: number
  bonus_pass_yd_300: number
  bonus_rec_wr: number
  pts_allow_21_27: number
  yds_allow_500_549: number
  fgm_20_29: number
  idp_sack: number
  rec_5_9: number
  yds_allow: number
  st_tkl_solo: number
  rush_att: number
  fgmiss_20_29: number
  kr_yd: number
  rec_fd: number
  pts_allow_1_6: number
  fum_lost: number
  def_st_fum_rec: number
  int: number
  idp_pass_def_3p: number
  idp_def_td: number
  fgm_0_19: number
  def_2pt: number
  pts_allow_14_20: number
  idp_safe: number
  idp_int_ret_yd: number
  yds_allow_200_299: number
  rec: number
  fgmiss_30_39: number
  bonus_sack_2p: number
  idp_int: number
  ff: number
  bonus_def_fum_td_50p: number
  fgmiss: number
  st_fum_rec: number
  pass_att: number
  idp_tkl_solo: number
  int_ret_yd: number
  pass_int_td: number
  rush_fd: number
  idp_tkl_loss: number
  bonus_pass_cmp_25: number
  rec_2pt: number
  bonus_rush_rec_yd_100: number
  idp_sack_yd: number
  fgm: number
  yds_allow_350_399: number
  def_st_tkl_solo: number
  pass_td_40p: number
  yds_allow_550p: number
  idp_tkl: number
  fg_ret_yd: number
  def_4_and_stop: number
  pts_allow: number
  bonus_fd_wr: number
  def_kr_yd: number
  rush_td: number
  rush_td_50p: number
  xpmiss: number
  idp_fum_ret_yd: number
  fgm_30_39: number
  idp_blk_kick: number
  rec_20_29: number
  tkl_ast: number
  yds_allow_400_449: number
  rec_td: number
  fgm_yds_over_30: number
  sack_yd: number
  rec_30_39: number
  def_pr_yd: number
  st_td: number
  blk_kick_ret_yd: number
  yds_allow_450_499: number
  rec_0_4: number
  pass_2pt: number
  bonus_pass_yd_400: number
  pts_allow_0: number
  fgmiss_50p: number
  pass_int: number
  bonus_rush_yd_100: number
  bonus_rec_yd_100: number
  def_forced_punts: number
  fgm_yds: number
  bonus_rec_te: number
  fgm_40_49: number
  qb_hit: number
  sack: number
}

export interface SleeperDraftSettings {
  teams: number
  rounds: number
  reversal_round: number
  player_type: SleeperDraftPlayerType
  pick_timer: number
  // undefined on non-auction drafts
  budget?: number
}

export interface SleeperLeague {
  total_rosters: number
  status: SleeperStatusTypes
  settings: SleeperLeagueSettings
  season_type: string
  season: string
  scoring_settings: SleeperScoreSettings
  roster_positions: string[]
  name: string
  league_id: string
  draft_id: string
  synced?: boolean
}

export interface SleeperUser {
  user_id: string
  display_name: string
  is_owner: boolean | null
  metadata?: {
    team_name?: string
  }
}

export interface SleeperDraftOrder {
  [key: string]: number
}

export interface SleeperDraft {
  type: SleeperDraftType
  status: string
  start_time: number
  sport: string
  settings: SleeperDraftSettings
  season_type: string
  season: string
  metadata: {
    scoring_type: string
    name: string
    description: string
  }
  league_id: string
  draft_order: SleeperDraftOrder | null
  draft_id: string
  creators: string[]
  created: number
}

export interface SleeperRoster {
  starters: string[]
  reserve: string[] | null // When user doesn't have any reserve player
  players: string[]
  league_id: string
  owner_id: string | null // Bots or CPU don't have id
  settings: MatchupOutcome
  roster_id: number
}
export interface SleeperDraftPick {
  round: number
  picked_by: string
  pick_no: number
  is_keeper: boolean | null
  metadata: {
    player_id: string
    last_name: string
    first_name: string
  }
}

export interface SleeperMatchup {
  matchup_id: number
  roster_id: number
}

export interface ESPNDraftPick {
  overallPickNumber: number
  playerId: number
  roundId: number
  teamId: number
}

export interface EspnDraftDetail {
  drafted: boolean
  inProgress: boolean
  picks: ESPNDraftPick[]
}

export type YahooPositions =
  | 'QB'
  | 'RB'
  | 'WR'
  | 'TE'
  | 'K'
  | 'DEF'
  | 'BN'
  | 'IR'
  | 'D'
  | 'W/R/T'
  | 'W/R'
  | 'W/T'
  | 'Q/W/R/T'

export interface RosterPositions {
  position: YahooPositions
  position_type: string
  count: number
  is_starting_position: number
}

export interface StatModifier {
  stat_id: number
  value: number
}

export interface StatCategory {
  stat_id: number
  enabled: number
  name: string
  display_name: string
  group: string
  abbr: string
  sort_order: number
  position_type: string
}

export interface YahooTokenResponse {
  access_token: string
  refresh_token: string
  expires_in: number
  token_type: string
}

export interface YahooDraftResult {
  pick: number
  round: number
  team_key: string
  player_key: string
}

export enum YahooDraftStatusTypes {
  PRE_DRAFT = 'predraft',
  DRAFTING = 'draft',
  POST_DRAFT = 'postdraft',
}

export interface YahooStandingsManager {
  guid: string
  is_current_login?: boolean
}

export interface YahooStandingsTeam {
  team_key: string
  name: string
  draft_position?: number
  is_owned_by_current_login?: number
  team_standings?: {
    outcome_totals: MatchupOutcome
  }
  managers: {
    manager: YahooStandingsManager[] | YahooStandingsManager
  }
}

export interface YahooLeagueStandingsAndSettings {
  fantasy_content: {
    league: {
      name: string
      num_teams: number
      draft_status: YahooDraftStatusTypes
      settings: YahooDraftSettingsField
      standings: YahooDraftStandingsField
    }
  }
}

type YahooDraftResultsField =
  | {
      draft_result: YahooDraftResult[]
    }
  | string // empty string if no draft results

type YahooDraftStandingsField = {
  teams: {
    team: YahooStandingsTeam[] | YahooStandingsTeam
  }
}

type YahooDraftSettingsField = {
  max_teams: number
  is_auction_draft: number
  draft_pick_time: number
  draft_time?: number
  roster_positions: {
    roster_position: RosterPositions[]
  }
  stat_categories: {
    stats: {
      stat: StatCategory[]
    }
  }
  stat_modifiers: {
    stats: {
      stat: StatModifier[]
    }
  }
}

// this is only a partial type, I only took the fields we are actually using
export interface YahooDraftResults {
  fantasy_content: {
    league: {
      name: string
      num_teams: number
      draft_status: YahooDraftStatusTypes
      draft_results: YahooDraftResultsField
    }
  }
}

// this is only a partial type, I only took the fields we are actually using
export interface YahooLeagueWithStandingsAndDraftResults {
  fantasy_content: {
    league: {
      name: string
      num_teams: number
      draft_status: YahooDraftStatusTypes
      standings: YahooDraftStandingsField
      draft_results: YahooDraftResultsField
    }
  }
}

export interface YahooMatchup {
  week: number
  teams: {
    team: {
      team_key: string
      team_id: number
      name: string
    }[]
  }
}

export interface YahooTeamMatchups {
  fantasy_content: {
    team: {
      team_key: string
      team_id: number
      name: string
      matchups: {
        matchup: YahooMatchup[]
      }
    }
  }
}

export interface YahooRosterPlayer {
  player_key: string
  player_id: number
  selected_position: {
    coverage_type: string
    week: number
    position: YahooPositions
    is_flex: number
  }
}

export interface YahooRoster {
  fantasy_content: {
    team: {
      name: string
      roster: {
        coverage_type: string
        week: number
        players: {
          player: YahooRosterPlayer[]
        }
      }
      managers: {
        manager: YahooStandingsManager[] | YahooStandingsManager
      }
    }
  }
}

export interface ESPNDraftStrategy {
  // undefined if no keepers
  keeperPlayerIds?: number[]
  // {} if no keepers MANUAL, undefined if no keepers TRADITIONAL | END_OF_DRAFT
  reservedDraftPickByKeeper?: Record<string, number> | {}
}

export interface ESPNRoster {
  lineupSlotId: number
  playerId: number
}

export interface ESPNTeam {
  id: number
  location: string
  nickname: string
  primaryOwner?: string // undefined if team isn't set yet
  owners?: string[] // set when the user is a co manager of a team
  name: string
  draftStrategy?: ESPNDraftStrategy // undefined if non-commissioner, cannot see other player's strategy
  record: {
    overall: MatchupOutcome
  }
  roster?: { entries: ESPNRoster[] } // Available only when view is set to mKeeperRosters
}

export interface ESPNMember {
  id: string
  displayName: string
  firstName: string
  lastName: string
}

export interface ScoringItem {
  isReverseItem: boolean
  leagueRanking: number
  leagueTotal: number
  points: number
  pointsOverrides?: Record<string, number | undefined>
  statId: number
}

export interface ESPNDraftSettings {
  keeperCount: number
  pickOrder: number[]
  keeperOrderType: 'TRADITIONAL' | 'END_OF_DRAFT' | 'MANUAL'
  type: 'SNAKE' | 'LINEAR' | 'AUCTION'
  auctionBudget: number
  timePerSelection: number
  date?: number // undefined if not scheduled yet
}

export interface ESPNLeagueSettings {
  name: string
  size: number
  rosterSettings: {
    lineupSlotCounts: Record<string, number>
  }
  scoringSettings: {
    scoringItems: ScoringItem[]
  }
  draftSettings: ESPNDraftSettings
}

export interface ESPNSchedule {
  playoffTierType?: string
  matchupPeriodId: number
  // Bye week teams wont have away attribute
  away?: {
    teamId: number
  }
  home: {
    teamId: number
  }
  winner?: string
}

export interface ESPNLeagueObject {
  settings: ESPNLeagueSettings
  teams: ESPNTeam[]
}

export interface ESPNLeagueObjectKeepers {
  settings: ESPNLeagueSettings
  members: ESPNMember[]
  teams: ESPNTeam[]
  draftDetail: EspnDraftDetail
}
export interface ESPNLeagueObjectMatchup {
  schedule: ESPNSchedule[]
  teams: ESPNTeam[]
}

export interface ESPNLeagueObjectSwid {
  league: ESPNLeagueObject
  swid: string
}

interface SleeperProviderProps {
  hostedSite: HostedSites.SLEEPER
}

interface YahooProviderProps {
  hostedSite: HostedSites.YAHOO
  accessToken: string
  refreshToken: string
  userId: number
  oauthCallbackUrl?: string
}

interface EspnProviderProps {
  hostedSite: HostedSites.ESPN
  swid: string
  espnS2: string
}

// ProviderProps should only contain the identifier and values needed to initialize the service or repository.
// All other params should be passed in as parameters to the individual methods. This allows us to call the different
// methods anywhere we have the required params available.
export type ProviderProps = SleeperProviderProps | YahooProviderProps | EspnProviderProps
