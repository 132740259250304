export const merlinEndpoints = {
  getPlayerActiveSeasons: ({ id }: { id: number }) => {
    return { url: `/api/players/${id}/seasons` }
  },
  getPlayerNews: ({ id }: { id: number }) => {
    return { url: `/api/players/${id}/news` }
  },
  getTeamNews: ({ id }: { id: number }) => {
    return { url: `/api/teams/${id}/news` }
  },
}
