import { createContext } from 'react'

interface PlayerProfileInterface {
  isPremiumSubscriber?: boolean
}

const defaultProviderValue = {
  isPremiumSubscriber: false,
}

export const PlayerProfileContext = createContext<PlayerProfileInterface>(defaultProviderValue)
