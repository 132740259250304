export enum PlayerProfileWidgetIds {
  LATEST_NEWS = 'latestNewsWidget',
  KEY_INSIGHTS = 'keyInsightsWidget',
  FANTASY = 'fantasyWidget',
  MATCHUPS = 'matchupWidget',
  HISTORICAL_PERFORMANCE = 'historicalPerformanceWidget',
  LAST_FIVE = 'lastFive',
  GRADES = 'gradesWidget',
  SEASON_STATS = 'seasonStatsWidget',
}

export enum PlayerProfileWidgetNames {
  LATEST_NEWS = 'LATEST NEWS',
  KEY_INSIGHTS = 'KEY INSIGHTS',
  FANTASY = 'FANTASY',
  MATCHUPS = 'MATCHUP',
  HISTORICAL_PERFORMANCE = 'PAST PERFORMANCE',
  LAST_FIVE = 'LAST 5 GAMES',
  GRADES = 'GRADES',
  SEASON_STATS = 'SEASON STATS',
}

export const PlayerProfileWidgetNamesMap = {
  [PlayerProfileWidgetNames.LATEST_NEWS]: `#${PlayerProfileWidgetIds.LATEST_NEWS}`,
  [PlayerProfileWidgetNames.KEY_INSIGHTS]: `#${PlayerProfileWidgetIds.KEY_INSIGHTS}`,
  [PlayerProfileWidgetNames.FANTASY]: `#${PlayerProfileWidgetIds.FANTASY}`,
  [PlayerProfileWidgetNames.MATCHUPS]: `#${PlayerProfileWidgetIds.MATCHUPS}`,
  [PlayerProfileWidgetNames.HISTORICAL_PERFORMANCE]: `#${PlayerProfileWidgetIds.HISTORICAL_PERFORMANCE}`,
  [PlayerProfileWidgetNames.LAST_FIVE]: `#${PlayerProfileWidgetIds.LAST_FIVE}`,
  [PlayerProfileWidgetNames.GRADES]: `#${PlayerProfileWidgetIds.GRADES}`,
  [PlayerProfileWidgetNames.SEASON_STATS]: `#${PlayerProfileWidgetIds.SEASON_STATS}`,
}
