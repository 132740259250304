import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import { getDraftersFromPicks, transformSyncedSettings } from '@pff-consumer/utils'
import { HostedSites, LiveDraftStatus, type MDSLeagueSettings } from '@pff-consumer/schema'
import {
  fantasySlice,
  mutateRankings,
  startLda,
  setDraftStatusForProvidersWithExtension,
} from '../../lib/fantasy/fantasy.slice'
import { fantasyLdaMdsApi } from '../../lib/fantasy-lda-mds/fantasy-lda-mds-api'
import type { RootState } from '../store.fantasy'

export const addFantasyLdaSpecificListeners = (startListening: AddListenerOverloads<unknown>) => {
  // Step: Transform the league settings appropriately for LDA
  startListening({
    actionCreator: startLda,
    effect: async (_action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      const state = listenerApi.getState() as RootState
      const { selectedSyncedLeague, leagueSettings, leagueKeepers } = state.fantasy.draftRoom

      if (selectedSyncedLeague) {
        const clonedLeague = { ...selectedSyncedLeague }
        const transformedSettings = transformSyncedSettings(
          clonedLeague,
          leagueSettings as MDSLeagueSettings,
          leagueKeepers
        )

        listenerApi.dispatch(fantasySlice.actions.resetAllPlayerAnimations())
        listenerApi.dispatch(fantasySlice.actions.updateLiveDraftMode(true))
        listenerApi.dispatch(fantasySlice.actions.updateDraftRoomLeagueSettings(transformedSettings))
      } else {
        console.error('Unable to find synced league')
      }
    },
  })

  // Step: Listen to when all draft pick happens in LDA
  startListening({
    actionCreator: fantasySlice.actions.updateLiveDraftPick,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      const state = listenerApi.getState() as RootState
      const totalRounds = state.fantasy.draftRoom.leagueSettings.rounds
      const userPicksLength = state.fantasy.draftRoom.userPicks.length
      listenerApi.dispatch(mutateRankings())
      if (userPicksLength < totalRounds) {
        listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSuggestionsLoading(true))
        listenerApi.dispatch(fantasySlice.actions.fetchNextPickInfo())
      }
    },
  })

  startListening({
    matcher: fantasyLdaMdsApi.endpoints.getLeagueDraftPicks.matchFulfilled,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      const state = listenerApi.getState() as RootState
      const { draftPicks, webSocketHasReceivedMessage } = state.fantasy.draftRoom

      const newPicks = action.payload.picks || []
      listenerApi.dispatch(fantasySlice.actions.updateAllLiveDraftPicks(action.payload.picks))
      listenerApi.dispatch(fantasySlice.actions.updateLiveDraftStatus(action.payload.status))

      // This does not handle picks being edited. This allows us to still get suggestions + next round availability if the websocket has not connected/received a message yet for whatever reason
      if (draftPicks.length !== newPicks.length && !webSocketHasReceivedMessage) {
        listenerApi.dispatch(fantasySlice.actions.fetchNextPickInfo())
      }
    },
  })

  startListening({
    actionCreator: setDraftStatusForProvidersWithExtension,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      const state = listenerApi.getState() as RootState
      const { selectedSyncedLeague } = state.fantasy.draftRoom
      if (!action.payload || !selectedSyncedLeague) return

      const useYahooExtensionFlow = action.payload && selectedSyncedLeague.hosted_site === HostedSites.YAHOO

      const isEspnLeague = selectedSyncedLeague.hosted_site === HostedSites.ESPN
      if (useYahooExtensionFlow || isEspnLeague) {
        const { data } = await listenerApi.dispatch(
          fantasyLdaMdsApi.endpoints.getLeagueDraftPicks.initiate({ leagueId: selectedSyncedLeague.id })
        )
        if (!data) return
        if (data.picks && data.status === LiveDraftStatus.COMPLETE) {
          listenerApi.dispatch(fantasySlice.actions.updateLeagueDrafters(getDraftersFromPicks(data.picks)))
        }
        listenerApi.dispatch(fantasySlice.actions.updateAllLiveDraftPicks(data.picks))
        listenerApi.dispatch(fantasySlice.actions.updateLiveDraftStatus(data.status))
      }
    },
  })
}
