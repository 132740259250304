import { CurrentSuggestionData, MDSAvailabilityPredictor, MDSPickInfo, NextSuggestionData } from '../fantasy-mds'

export enum LiveDraftStatus {
  PRE_DRAFT = 'predraft',
  DRAFTING = 'drafting',
  COMPLETE = 'complete',
  STALE = 'stale',
  ERROR = 'error',
}

export type LiveDraftStatusType = `${LiveDraftStatus}`

export interface LiveDraftLeagueStatus {
  status: LiveDraftStatus
  lastPickTime?: string
}

export interface ExtensionDraftPick {
  roundPickNumber?: number
  round: number
  draftPick?: number
  draftedPlayerId: string
  drafteeName: string
  draftedPlayer: string
}

export interface LiveDraftPickBase extends ExtensionDraftPick {
  draftPick: number
}

export interface LiveDraftPick extends LiveDraftPickBase {
  leagueId: string
  pickedAt?: string
}

export interface LiveDraftPicksStore {
  [key: string]: LiveDraftPick
}

export interface PicksWithStatus {
  picks: LiveDraftPick[]
  status: LiveDraftStatus
}

export interface LiveDraftPickMessage {
  leagueId: string
  draftStatus: LiveDraftStatusType
  latestPicks: LiveDraftPick[]
  draftAll: boolean
  errorMessage?: string
  authorizeUrl?: string
}

export interface NextRoundInfo {
  playerId: string
  pickGrade: number // 'proj_PFF_s is the assoicated value'
  availability: number // 'available_1 is the assoicated value'
  positionalRank?: number
}

export interface LiveDraftStatusResponse {
  draftStatus: string
  timestamp: string
}

export interface LiveDraftStatusYahooOauthErrorResponse {
  authorizeUrl: string
  draftStatus: string
  errorMessage: string
  timestamp: string
}

export interface NextRoundPlayer extends NextRoundInfo {
  firstName: string
  lastName: string
  team: string
  position: string
  teamCity: string
  teamName: string
}

export interface NextPickInfo {
  playerId: string
  pickGrade: number // 'PFF_s is the assoicated value'
  availability: number // 'available_1 is the assoicated value'
  points: number
  nextRoundPlayers: NextRoundInfo[]
  positionalRank?: number
}

export interface NextPickInfoSuggestion extends NextPickInfo {
  firstName: string
  lastName: string
  team: string
  position: string
  bye: number
  teamCity: string
  teamName: string
  nextRoundPlayers: NextRoundPlayer[]
}

export type PlayerPickGrades = Record<string, number>

export interface PlayerNextRoundAvailability {
  [playerId: number]: number[]
}

export interface NextPickInfoSuggestionsTransformedResponse {
  pickInfo?: MDSPickInfo
  suggestions: {
    round: number | undefined
    suggestions: NextPickInfo[]
  }
  pickGrades: PlayerPickGrades
  nextRoundAvailability: PlayerNextRoundAvailability
}

export interface NextPickInfoSuggestionsApiResponse {
  // TODO - change to pickinfo instead of nextpickinfo
  nextPickInfo?: MDSPickInfo
  predictedAvailablePlayers: MDSAvailabilityPredictor
  suggestions: CurrentSuggestionData
  suggestedNextPicks: Record<string, NextSuggestionData>
}

export interface ChromeLivePicks {
  action: string
  payload: any
}

export const instanceOfLiveDraftStatusResponse = (object: any): object is LiveDraftStatusResponse => {
  return object.draftStatus && object.timestamp
}

export const instanceOfLiveDraftErrorResponse = (object: any): object is LiveDraftStatusYahooOauthErrorResponse => {
  return object.errorMessage && object.timestamp && object.draftStatus === 'error'
}
